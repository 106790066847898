import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    // role: ['All'], 
    submenu: [],
  },

  // Admin Modules

  {
    path: '',
    title: 'MENUITEMS.HOME.TEXT',
    moduleName: 'dashboard',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    // role: ['All'], 
    submenu: [
      {
        path: '/admin/dashboard/main',
        title: 'MENUITEMS.HOME.LIST.DASHBOARD1',
        moduleName: 'dashboard',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        // role: ['All'], 
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'Modules',
    moduleName: 'workers',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    // role: ['All'], 
    submenu: [
      // {
      //   path: '/admin/components/all-admins',
      //   title: 'Admins',
      //   moduleName: 'admins',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   // role: ['All'], 
      //   submenu: [],
      // },
      {
        path: '/admin/components/all-staff',
        title: 'Users',
        moduleName: 'users',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        // role: ['All'], 
        submenu: [],
      },
      // {
      //   path: '/admin/components/all-shopkeepers',
      //   title: 'Shopkeepers',
      //   moduleName: 'shopkeepers',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   // role: ['All'], 
      //   submenu: [],
      // },
      // {
      //   path: '/admin/components/all-shopowners',
      //   title: 'Shopowners',
      //   moduleName: 'shopowners',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   // role: ['All'], 
      //   submenu: [],
      // },
      {
        path: '/admin/components/all-users-credits',
        title: 'Users With Credit',
        moduleName: 'userWithCredits',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        // role: ['All'], 
        submenu: [],
      },
    ],
  },

  {
    path: '',
    title: 'Stores Module',
    moduleName: 'stores',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    // role: ['All'], 
    submenu: [     
      {
         path: '/admin/components/all-stores',
         title: 'Stores',
         moduleName: 'stores',
         iconType: '',
         icon: '',
         class: 'ml-menu',
         groupTitle: false,
         badge: '',
         badgeClass: '',
         // role: ['All'], 
         submenu: [],
       },
       {
          path: '/admin/components/all-stores/offer',
          title: 'Offer Products',
          moduleName: 'offerProducts',
          iconType: '',
          icon: '',
          class: 'ml-menu',
          groupTitle: false,
          badge: '',
          badgeClass: '',
          // role: ['All'], 
          submenu: [],
        },
        {
           path: '/admin/components/all-stores/no-stock',
           title: 'No Stock Products',
           moduleName: 'noStockProducts',
           iconType: '',
           icon: '',
           class: 'ml-menu',
           groupTitle: false,
           badge: '',
           badgeClass: '',
           // role: ['All'], 
           submenu: [],
         },
         {
            path: '/admin/components/all-stores/coupon-orders',
            title: 'Orders with coupons',
            moduleName: 'ordersWithCoupons',
            iconType: '',
            icon: '',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            // role: ['All'], 
            submenu: [],
          }
    ],
  },

  //Kitechen Module
  {
    path: '',
    title: 'Kitchen Module',
    moduleName: 'vendors',
    iconType: 'material-icons-two-tone',
    icon: 'home',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    // role: ['All'], 
    submenu: [     
      {
         path: '/admin/components/all-vendors-shops',
         title: 'Stores',
         moduleName: 'vendorstores',
         iconType: '',
         icon: '',
         class: 'ml-menu',
         groupTitle: false,
         badge: '',
         badgeClass: '',
         // role: ['All'], 
         submenu: [],
       }
    ],
  },
  // Teacher Modules

  // {
  //   path: '/teacher/dashboard',
  //   title: 'MENUITEMS.TEACHER.LIST.DASHBOARD',
  //   moduleName: 'dashboard',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'home',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   // role: [''], 
  //   submenu: [],
  // },

  // // Student Modules

  // {
  //   path: '/student/dashboard',
  //   title: 'MENUITEMS.STUDENT.LIST.DASHBOARD',
  //   moduleName: 'dashboard',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'home',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   // role: [''], 
  //   submenu: [],
  // },

  // // Common Module

  // {
  //   path: '',
  //   title: 'Authentication',
  //   moduleName: 'authentication',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'supervised_user_circle',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   // role: [''], 
  //   submenu: [
  //     {
  //       path: '/authentication/signin',
  //       title: 'Sign In',
  //       moduleName: 'authentication',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/signup',
  //       title: 'Sign Up',
  //       moduleName: 'authentication',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/forgot-password',
  //       title: 'Forgot Password',
  //       moduleName: 'authentication',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/locked',
  //       title: 'Locked',
  //       moduleName: 'authentication',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/page404',
  //       title: '404 - Not Found',
  //       moduleName: 'authentication',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/page500',
  //       title: '500 - Server Error',
  //       moduleName: 'authentication',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Extra Pages',
  //   moduleName: 'extra-pages',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'description',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   // role: [''], 
  //   submenu: [
  //     {
  //       path: '/extra-pages/blank',
  //       title: 'Blank Page',
  //       moduleName: 'extra-pages',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Multi level Menu',
  //   moduleName: 'multilevel',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'slideshow',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   // role: [''], 
  //   submenu: [
  //     {
  //       path: '/multilevel/first1',
  //       title: 'First',
  //       moduleName: 'multilevel',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //     {
  //       path: '/',
  //       title: 'Second',
  //       moduleName: 'secondlevel',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-sub-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [
  //         {
  //           path: '/multilevel/secondlevel/second1',
  //           title: 'Second 1',
  //           moduleName: 'secondlevel',
  //           iconType: '',
  //           icon: '',
  //           class: 'ml-sub-sub-menu',
  //           groupTitle: false,
  //           badge: '',
  //           badgeClass: '',
  //           // role: [''], 
  //           submenu: [],
  //         },
  //         {
  //           path: '/multilevel/secondlevel/second2',
  //           title: 'Second 2',
  //           moduleName: 'secondlevel',
  //           iconType: '',
  //           icon: '',
  //           class: 'ml-sub-sub-menu',
  //           groupTitle: false,
  //           badge: '',
  //           badgeClass: '',
  //           // role: [''], 
  //           submenu: [],
  //         },
  //       ],
  //     },
  //     {
  //       path: '/multilevel/first3',
  //       title: 'Third',
  //       moduleName: 'multilevel',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       // role: [''], 
  //       submenu: [],
  //     },
  //   ],
  // },
];
