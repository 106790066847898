import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { StaffService } from 'src/app/shared/services/staff.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Staff } from 'src/app/shared/models/staff.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  user: any;
  constructor(private http: HttpClient, 
    private router: Router,
    private af: AngularFireDatabase,
    private db: AngularFirestore,
    public  afAuth:  AngularFireAuth) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  
  async login(email: string, password: string) {
    /*var result = await this.afAuth.signInWithEmailAndPassword(email, password);
    */
      this.afAuth.signInWithEmailAndPassword(email, password).then(value => {
      this.afAuth.authState.subscribe(async user => {
        if (user){
          let getUser = JSON.parse(JSON.stringify(user));
          this.db.collection("users").doc(getUser.uid).ref.get().then(doc =>{
            let checkUserRole = doc.data() as Staff;
            if(checkUserRole.userRoles && checkUserRole.userRoles.length > 0){
              let adminRole = checkUserRole.userRoles.find(x => x == 'Super Admin');
              if(adminRole != null){
                localStorage.setItem('user', JSON.stringify(this.user));//old code
                // this.currentUserValue.role = "Admin";
                this.router.navigate(['../../admin/dashboard/main']); //old code
              }
              else{
                alert('User are not Admin'); 
              }
            }
            else{
              alert('User are not Admin');
            }

            
          })
          
          // end new code
          //this.user = user;//old code
          console.log("Here")
          //localStorage.setItem('user', JSON.stringify(this.user));//old code
          // this.currentUserValue.role = "Admin";
          //this.router.navigate(['../../admin/dashboard/main']); //old code
          // await this.af.object('adminUsers/' + user.uid).valueChanges().subscribe(userData => {
          //   if(userData["isActive"] == "true"){
          //     localStorage.setItem('user_data', JSON.stringify(userData));
          //     localStorage.setItem('communityGroups', JSON.stringify(userData['communityGroups']));
          //     localStorage.setItem('communityTypes', JSON.stringify(userData['communityTypes']));
          //     if(userData['userRole'] != "Super Admin"){
          //       this.af.object('adminRolesPermissions/' + userData['userRole'] + '/permissions').valueChanges().subscribe(permissions => {
          //         if(Object.values(permissions).length>0){
          //           localStorage.setItem('permissions', JSON.stringify(permissions));
          //       }
          //       });
          //     }
          //     console.log(localStorage.getItem('communityTypes'))
          //     this.router.navigate(['/dashboard/dashboard2']);
          //   }
          //   else{  
          //     this.afAuth.signOut();
          //     alert("We are sorry\nYour account has not been activated.\nWe have sent a reminder to the community manager.")
          //   }
          // });
        } else {
          console.log("No here")
          localStorage.setItem('user', null);
        }
      })
  })
  .catch(err => {
    alert(`'Something went wrong:', ${err.message}`);
  });
}
get isLoggedIn(): boolean {
  const  user  =  JSON.parse(localStorage.getItem('user'));
  return  user  !==  null;
}
logout() {   
  localStorage.removeItem('user')
  localStorage.removeItem('user_data')
  localStorage.removeItem('communityGroups')
  localStorage.removeItem('communityTypes')
  localStorage.removeItem('permissions')
  this.afAuth.signOut();
  this.router.navigate([""]);
}
resetPassword(email: string) {   
  this.afAuth.sendPasswordResetEmail(email);
  alert("Password reset email send");
  this.router.navigate([""]);
}

isAuthorized(link): boolean {
  var userRole = JSON.parse(localStorage.getItem('user_data')).userRole;
  if(userRole != "Super Admin"){
  var permissions = JSON.parse(localStorage.getItem('permissions'));
  console.log(permissions.includes(link))
  return permissions.includes(link)
}
else {
  return true;
}
}
}